
import request from "@/utils/request";

const test = location.origin.indexOf("test-asa.qiumo.net.cn") >= 0;
// 供应商
const ipBaseURL = test ? 'http://10.12.21.173:8086/marketapi/' : "https://asa.qiumo.net.cn/marketapi/"
// 读赞/互金
const ipBaseURLDuZan = test ? 'http://10.12.21.173:8086' : "https://pojie.ai-me.cn/"


// 星力海蓝验证码
export const getVerificationCode = (data) => request({
  url: '/package/order/getVerificationCode',
  method: 'post',
  data,
  ipBaseURL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
});

//  星力海蓝验立即领取
export const tradeOrder = (data) => request({
  url: '/package/order/tradeOrder',
  method: 'post',
  data,
  ipBaseURL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
});

// 地市
// export const locationByPhone = (data) => request({
//   ipBaseURL,
//   url: `/package/order/locationByPhone`,
//   method: 'post',
//   data,
// });

// 获取手机号地市
export const hnPhoneCheck = (phone) => request({
  ipBaseURL,
  url: `/package/order/hnPhoneCheck/${phone}`,
  method: 'get',
});

// 获取手机号地市
export const cityPhoneCheck = (phone) => request({
  ipBaseURL,
  url: `/package/order/cityPhoneCheck/${phone}`,
  method: 'get',
});

// 尾量信息保存
export const saveJump = (data) => request({
  ipBaseURL,
  url: `/package/order/tailJump`,
  method: 'post',
  data,
});


// --------------start读赞------------

// 发送短信
export const sendSMS = (data, params) =>
  request({
    url: "/op-service/v1/zunxiang20240613/send-sms/",
    method: "post",
    ipBaseURL: ipBaseURLDuZan,
    data,
    params,
  });

// 订购业务
export const buy = (data, params) =>
  request({
    url: "/op-service/v1/zunxiang20240613/buy/",
    method: "post",
    ipBaseURL: ipBaseURLDuZan,
    data,
    params,
  });
// --------------end读赞------------

// --------------start互金------------
// 发送短信
export const huHinSendSMS = (data) =>
  request({
    url: "/op-service/v1/hj20240905/send-sms/",
    method: "post",
    ipBaseURL: ipBaseURLDuZan,
    data,
  });
// 订购业务
export const huHinBuy = (data) =>
  request({
    url: "/op-service/v1/hj20240905/buy/",
    method: "post",
    ipBaseURL: ipBaseURLDuZan,
    data,
  });

// --------------end互金------------

// --------------start亿宏------------
// 发送短信
export const yiHongSendSMS = (data) =>
  request({
    url: "/op-service/v1/yh20240912/send-sms/",
    method: "post",
    ipBaseURL: ipBaseURLDuZan,
    data,
  });
// 订购业务
export const yiHongBuy = (data) =>
  request({
    url: "/op-service/v1/yh20240912/buy/",
    method: "post",
    ipBaseURL: ipBaseURLDuZan,
    data,
  });

// --------------end亿宏------------

