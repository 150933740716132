import { saveJump } from "@/api/qiuMoFusion/index";
export default {
  methods: {
    /* 
    *------尾量参数------
      *path: 将要跳转的页面
      *from: 特征值
      *phone: 手机号 
      *rootUrl: 根链接
      *parentUrl: 父链接
      *level: 跳转层级
      *reasonCode: 跳转原因code（001:成功  002:失败  003:退出 004外省号码）
     */
    tailJumpUrl(path = "", from = "", phone = "", reasonCode = "") {
      const { origin = "", pathname = "" } = location;
      const { level = "0", rootUrl = "" } = this.$route.query;

      const code = {
        "成功": "001",
        "失败": "002",
        "退出": "003",
        "外省号码": "004",
        "验证码下发失败": "005",
      };
      return `${path}?from=${from}&phone=${phone}&reasonCode=${reasonCode && code[reasonCode] || reasonCode}&rootUrl=${rootUrl}&parentUrl=${origin}${pathname}&level=${Number(level) + 1}`;
    },
    // 下发验证码成功后尾量信息保存
    async handleJump() {
      const { productNo = "", phone = "" } = this.form;
      const { rootUrl = "", level = "0", parentUrl = "", reasonCode = "" } = this.$route.query;
      const res = await saveJump({
        productNo,
        phone,
        rootUrl,
        parentUrl,
        reasonCode,
        level,
      });
      console.log(res)
    },
  },
}